import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import OperativePage from '@/types/OperativePage';

function useOperativePage() {
  const location = useLocation();
  const [isOperativePage, setIsOperativePage] = useState<boolean>(false);
  const [isSubOperativePage, setIsSubOperativePage] = useState<boolean>(false);

  const operativePages: OperativePage[] = [
    'supply_orders', 
    'solpeds', 
    'inventory', 
    'entry_plans',
  ];

  const isOperativePageByPath = (path: string): boolean => operativePages.some(page => path.includes(page));
  const isSubOperativePageByPath = (path: string): boolean => {
    const isOpPage = isOperativePageByPath(path);
    const arrPath = path.split('/');
    return isOpPage && arrPath.length > 2;
  };

  useEffect(() => {
    const { pathname } = location;
    
    setIsOperativePage(isOperativePageByPath(pathname));
    setIsSubOperativePage(isSubOperativePageByPath(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  
  return {
    isSubOperativePage,
    isOperativePage,
  };
}

export default useOperativePage;
