import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const FranchiseDetails = lazy(() => import('@/containers/Views/Franchise/FranchiseDetails/index'));
const FranchiseEditForm = lazy(() => import('@/containers/Views/Franchise/FranchiseForm/franchiseEdit'));
const PageCreateOperative = lazy(() => import('@/containers/Views/Operative/OperativesForm/PageCreateOperative'));
const FranchiseStaffDetailPage = lazy(() => import('@/containers/Views/Staff/FranchiseStaffDetailPage'));
const FranchisesPage = lazy(() => import('@/containers/Views/Franchise/FranchisesPage'));

const FranchiseRouter = (
  <Route path="franchises">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.Franchises} 
          permissions={EBasicActions.Find}
        >
          <FranchisesPage />
        </PrivateRoute>
      )} 
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Franchises} 
          permissions={EBasicActions.Edit}
        >
          <FranchiseEditForm />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/operatives/new"
      element={(
        <PrivateRoute 
          pageName={EPage.Operatives} 
          permissions={EBasicActions.Create}
        >
          <PageCreateOperative />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id/staff/config/:staffId"
      element={(
        <PrivateRoute 
          pageName={EPage.Staff} 
          permissions={[EBasicActions.Get, EBasicActions.Edit]}
        >
          <FranchiseStaffDetailPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id"
      element={(
        <PrivateRoute 
          pageName={EPage.Franchises} 
          permissions={EBasicActions.Get}
        >
          <FranchiseDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default FranchiseRouter;
