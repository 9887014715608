import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const WarehousesPage = lazy(() => import('@/containers/Views/Warehouse/WarehousesPage'));
const WarehouseDetails = lazy(() => import('@/containers/Views/Warehouse/WarehouseDetails/index'));
const WarehouseEditForm = lazy(() => import('@/containers/Views/Warehouse/WarehouseForm/warehouseEdit'));

const WarehouseRouter = (
  <Route path="warehouses">
    <Route 
      index
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Find}
        >
          <WarehousesPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path="edit/:id"
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Edit}
        >
          <WarehouseEditForm />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":id" 
      element={(
        <PrivateRoute 
          pageName={EPage.Warehouses} 
          permissions={EBasicActions.Get}
        >
          <WarehouseDetails />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default WarehouseRouter;
