import React from 'react';
import Unit from '@/interfaces/Unit';

interface IQuantityUnitProps{
  quantity: string | number,
  unit: Unit,
}

const QuantityUnit = ({
  quantity,
  unit,
}: IQuantityUnitProps) => (
  <>
    {quantity} {unit.code}
  </>
);

export default QuantityUnit;
